import React from "react";
import NavSide from "./components/SideNav";
import ProgressBar from "./components/Common/pb";
import { convertToUnix } from "./helpers/DateTime";
import { HOST, REPORT, REQUIREMENTS, ZONES } from "./helpers/Constants";
import { buildCollection } from "./modules/buildResults";
import { generateExcel } from "./modules/export";
import Snackbar from "./components/Common/Snackbar";
const queryString = require("query-string");

/* global wialon */

const groupReport = "avl_unit_group";
const unitReport = "avl_unit";

const targetObjects = require("./assets/files/constants_lists.json")
  .target_objects;

class Main extends React.Component {
  constructor() {
    super();

    // default dates values
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);

    this.state = {
      // demo token
      loginMode: "authHash",
      isTried: false,
      token: "",
      sessionID: "",
      engineHoursTable: [], // engine hours
      nonVisitedTable: [], // non- visited geofences
      ehCol: [],
      geoCol: [],
      resultData: [], // col of response
      isAuthorized: false,
      units: [],
      groups: [],
      reports: [],
      targetObjects: targetObjects,
      selectedTargetObject: targetObjects[1], // group is default value
      resources: [],
      selectedFromDate: startDate,
      selectedToDate: endDate,
      exeReportConfig: {
        res: {},
        groupReport: {}, // put report when we found it in res iteration
        unitReport: {},
        unitGroup: {},
        unit: {},
        interval: {
          from: -1,
          to: -1,
          flags: wialon.item.MReport.intervalFlag.absolute
        },
        type: "avl_unit_group" // synced with default type to execute
      },
      executing: true,
      zones: [], // if there is need for using zones
      timerID: 0, // if there is timer
      alert: {
        title: "",
        severity: "",
        msg: "",
        autoHide: false
      }
    };

    this.onChangeTargetObjects = this.onChangeTargetObjects.bind(this);

    this.onFromDateChange = this.onFromDateChange.bind(this);
    this.onToDateChange = this.onToDateChange.bind(this);

    this.onUnitChange = this.onUnitChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);

    this.execute = this.execute.bind(this);
    this.closeAlert = this.closeAlert.bind(this);

    this.export = this.export.bind(this);
  }

  componentDidMount() {}
  loginFunction = async code => {
    if (code) {
      this.setState({
        isTried: true,
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: wialon.core.Errors.getErrorText(code),
          autoHide: false
        }
      });
      //   alert(wialon.core.Errors.getErrorText(code));
      //   window.location.href = HOST.URL;
      return;
    }

    await this.setState({
      isAuthorized: true,
      isTried: true,
      alert: {
        ...this.state.alert,
        severity: "success",
        title: "Success",
        msg: "تم تسجيل الدخول بنجاح",
        autoHide: true
      }
    });
    await this.init2();
  };
  componentWillUnmount() {
    if (this.state.timerID > 0) {
      clearInterval(this.state.timerID);
    }
  }
  export(e) {
    if (!this.state.dataset) {
      this.setState({
        alert: {
          ...this.state.alert,
          title: "تنبيه",
          severity: "warning",
          msg: "لا تتوفر بيانات للتصدير",
          autoHide: true
        }
      });
      return;
    }
    let fn =
      "تقرير " +
      this.state.selectedFromDate.toLocaleDateString() +
      "_" +
      this.state.selectedToDate.toLocaleDateString() +
      "_" +
      new Date().getTime() / 1000;
    generateExcel(this.state.dataset, fn + ".xlsx");
  }

  onUnitChange(e, value) {
    if (value) {
      let that = this;
      this.setState({
        exeReportConfig: {
          ...that.state.exeReportConfig,
          unit: value
        }
      });
    }
  }
  onGroupChange(e, value) {
    if (value) {
      let that = this;
      this.setState({
        exeReportConfig: {
          ...that.state.exeReportConfig,
          unitGroup: value
        }
      });
    }
  }

  async init2() {
    const sess = wialon.core.Session.getInstance(); // get instance of current Session
    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.reports;

    res_flags = 1060865; //
    sess.loadLibrary("itemCustomFields");
    sess.loadLibrary("resourceReports");
    sess.loadLibrary("resourceZoneGroups");
    sess.loadLibrary("resourceZones");

    let that = this;
    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: 1 + 8, mode: 0 },
        { type: "type", data: "avl_unit", flags: 1 + 8, mode: 0 }
      ], // Items specification
      async function(code) {
        await that.setup(sess, code);
      }
    );
    await this.setState({
      sessionID: sess
    });
  }

  render() {
    const { isAuthorized } = this.state;

    if (this.state.loginMode === "authHash") {
      let hash = queryString.parse(this.props.location.search).authHash;

      if (!hash) {
        window.location.href = HOST.URL;
        return;
      }
      if (hash && hash.length === 32 && !this.state.isTried) {
        this.auth1(hash);
      } else {
        // console.log('invalid checking with hash '+ctx.value.isTried)
      }
    } else {
      if (!this.state.isTried) {
        this.auth1("");
      }
    }
    return (
      <div className="App">
        {!isAuthorized ? (
          <div className="cen">
            <ProgressBar className="cen-item" color="secondary" />
          </div>
        ) : (
          <div>
            {this.state.executing ? (
              <div className="cen">
                <ProgressBar className="cen-item" />
              </div>
            ) : !this.state.dataset ? (
              <div className="cen">
                <ProgressBar className="cen-item" />
              </div>
            ) : (
              <NavSide
                values={{
                  selectedTargetObject: this.state.selectedTargetObject,
                  selectedFromDate: this.state.selectedFromDate,
                  selectedToDate: this.state.selectedToDate,
                  selectedGroup: this.state.exeReportConfig.unitGroup,
                  selectedUnit: this.state.exeReportConfig.unit,
                  dataset: this.state.dataset,
                  canExport: this.state.dataset ? true : false
                }}
                lists={{
                  units: this.state.units,
                  groups: this.state.groups,
                  targetObjects: this.state.targetObjects
                }}
                functions={{
                  onChangeTargetObjects: this.onChangeTargetObjects,
                  onGroupChange: this.onGroupChange,
                  onUnitChange: this.onUnitChange,
                  onFromDateChange: this.onFromDateChange,
                  onToDateChange: this.onToDateChange,
                  execute: this.execute,
                  export: this.export
                }}
                zones={this.state.zones}
              ></NavSide>
            )}
          </div>
        )}
        {this.state.alert.title === "" ? null : (
          <Snackbar
            autoHide={this.state.alert.autoHide}
            severity={this.state.alert.severity}
            title={this.state.alert.title}
            msg={this.state.alert.msg}
            open={this.state.alert.title === "" ? false : true}
            closeAlert={this.closeAlert}
          />
        )}
      </div>
    );
  }

  closeAlert(e, reason) {
    if (reason === "clickaway") {
      // console.log("away");
      return;
    }

    this.setState({
      ...this.state,
      alert: {
        ...this.state.alert,
        severity: "",
        title: "",
        msg: "",
        autoHide: false
      },
      executing: false
    });
  }

  async auth1(e) {
    this.auth(e);
  }
  async auth(hashOrToken) {
    wialon.core.Session.getInstance().initSession(HOST.URL);

    if (this.state.loginMode == "token") {
      const token = this.state.token;
      wialon.core.Session.getInstance().loginToken(
        token,
        "", // try to login
        this.loginFunction
      );
    } else {
      let hash = queryString.parse(this.props.location.search).authHash;

      wialon.core.Session.getInstance().loginAuthHash(
        hash,
        "", // try to login
        this.loginFunction
      );
    }
  }

  async setup(sess, code) {
    // updateDataFlags callback
    if (code) {
      this.setState({
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: wialon.core.Errors.getErrorText(code),
          autoHide: false
        }
      });
      return;
    } // exit if error code

    await this.collectResources(sess);
    if (REQUIREMENTS.REPORTS) await this.findReports();

    let zonesGroup = "";
    if (REQUIREMENTS.ZONES_GROUPS) zonesGroup = await this.findStationGroup();

    let zonesArr = [];
    if (REQUIREMENTS.ZONES) zonesArr = await this.findZones();

    await this.setState({ zones: zonesArr });
    await this.executeReport1(sess);
  }

  async collectResources(sess) {
    const res = sess.getItems("avl_resource");
    if (!res || !res.length) {
      this.setState({
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: "Resources not found",
          autoHide: false
        }
      });
      // window.location.href = HOST.URL;
      return;
    }

    // get loaded 'avl_unit's items
    const units = sess.getItems("avl_unit");
    if (REQUIREMENTS.UNIT && !units) {
      this.setState({
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: "Units not found",
          autoHide: false
        }
      });
      window.location.href = HOST.URL;
      return;
    } // check if units found
    units.sort(function(a, b) {
      if (a.$$user_name > b.$$user_name) return 1;
      if (a.$$user_name < b.$$user_name) return -1;
      return 0;
    });

    const unitGroupsArr = sess.getItems("avl_unit_group");

    if (REQUIREMENTS.GROUP && !unitGroupsArr) {
      this.setState({
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: "Groups not found",
          autoHide: false
        }
      });
      return;
    }
    await this.setState({
      resources: res,
      units: units,
      groups: unitGroupsArr
    });
  }

  async findReports() {
    let isGroupReportFound = false;
    let isUnitReportFound = false;
    let tempExeReport = {
      res: {},
      groupReport: {}, // put report when we found it in res iteration
      unitReport: {},
      unitGroup: {},
      unit: {},
      interval: {
        from: -1,
        to: -1,
        flags: wialon.item.MReport.intervalFlag.absolute
      },
      type: groupReport
    };
    for (var i in this.state.resources) {
      if (this.state.resources[i].hasOwnProperty("$$user_reports")) {
        if (REPORT.AVL === "both") {
          if (isGroupReportFound && isUnitReportFound) {
            break;
          }
        } else if (REPORT.AVL === "group") {
          if (isGroupReportFound) {
            break;
          }
        } else {
          if (isUnitReportFound) {
            break;
          }
        }

        var rep = this.state.resources[i].getReports();
        for (var j in rep) {
          if (
            rep[j].ct === "avl_unit_group" &&
            rep[j].n === REPORT.GROUP_NAME
          ) {
            tempExeReport.groupReport = rep[j];
            isGroupReportFound = true;
            tempExeReport.res = this.state.resources[i];
            tempExeReport.unit = null;
            tempExeReport.unitGroup = this.state.groups[0]; //. set default

            tempExeReport.interval = {
              from: 1574542800,
              to: -1,
              flags: wialon.item.MReport.intervalFlag.absolute
            };
          }
          if (rep[j].ct === "avl_unit" && rep[j].n === REPORT.UNIT_NAME) {
            tempExeReport.unitReport = rep[j];
            isUnitReportFound = true;

            tempExeReport.res = this.state.resources[i];
            tempExeReport.unit = this.state.units[0];
            tempExeReport.unitGroup = null; //. set default
            tempExeReport.interval = {
              from: 1574542800,
              to: 0,
              flags: wialon.item.MReport.intervalFlag.absolute
            };
          }
          if (REPORT.AVL === "both") {
            if (isGroupReportFound && isUnitReportFound) {
              break;
            }
          } else if (REPORT.AVL === "group") {
            if (isGroupReportFound) {
              break;
            }
          } else {
            if (isUnitReportFound) {
              break;
            }
          }
        }
      }
    }
    await this.setState({ exeReportConfig: tempExeReport });
    return tempExeReport;
  }

  findStationGroup() {
    let stationsGroup;
    if (!this.state.exeReportConfig.res.hasOwnProperty("$$user_zonesGroups")) {
      if (REQUIREMENTS.ZONES_GROUPS) {
        return null;
      }
    }
    // now get geo group
    let isGroupFound = false;
    for (var i in this.state.exeReportConfig.res.$$user_zonesGroups) {
      if (
        this.state.exeReportConfig.res.$$user_zonesGroups[i].n ===
        ZONES.GROUP.NAME
      ) {
        stationsGroup = this.state.exeReportConfig.res.$$user_zonesGroups[i].n;
        isGroupFound = true;
        break;
      }
    }
    return stationsGroup;
  }
  findZones() {
    if (!this.state.exeReportConfig.res.hasOwnProperty("$$user_zones")) {
      return null;
    }
    return this.state.exeReportConfig.res.$$user_zones;
  }

  async executeReport1(sess) {
    let that = this;
    if (this.state.exeReportConfig.res.hasOwnProperty("_id")) {
      // setLocalTime Zone
      var localeObj = { flags: 0, formatDate: "%25Y-%25m-%25E %25H:%25M:%25S" };
      sess
        .getRenderer()
        .setLocale(134228528, "en", localeObj, async function(code) {
          if (code) {
            return;
          }
          await that.executeReport2(sess);
        });
    } else {
      this.setState({
        alert: {
          ...this.state.alert,
          severity: "error",
          title: "Error",
          msg: "Resource not found"
        }
      });
      // window.location.href = HOST.URL
      return;
    }
  }
  async executeReport2(sess) {
    var res3 = sess.getItem(this.state.exeReportConfig.res._id);

    var temp = {};

    var objectID = 0;
    if (this.state.exeReportConfig.type == groupReport) {
      objectID = this.state.exeReportConfig.unitGroup._id;
      temp = res3.getReport(this.state.exeReportConfig.groupReport.id);
    }
    if (this.state.exeReportConfig.type == unitReport) {
      objectID = this.state.exeReportConfig.unit._id;
      temp = res3.getReport(this.state.exeReportConfig.groupReport.id);
    }
    let fromUnix = convertToUnix(this.state.selectedFromDate);
    let toUnix = convertToUnix(this.state.selectedToDate);

    await this.setState(prevState => ({
      ...prevState,
      exeReportConfig: {
        ...prevState.exeReportConfig,
        interval: {
          ...prevState.exeReportConfig.interval,
          from: fromUnix,
          to: toUnix
        }
      }
    }));

    let that = this;

    res3.execReport(
      temp,
      objectID,
      0,
      this.state.exeReportConfig.interval, // execute selected report
      async function(code, data) {
        // execReport template

        if (code) {
          this.setState({
            alert: {
              ...this.state.alert,
              severity: "error",
              title: "Error",
              msg: wialon.core.Errors.getErrorText(code),
              autoHide: false
            }
          });
          return;
        } // exit if error code
        if (!data.getTables().length) {
          this.setState({
            alert: {
              ...this.state.alert,
              severity: "warning",
              title: "تنبيه",
              msg: "لا يوجد بيانات لعرضها"
            }
          });
          await that.setState({ executing: false });
          return;
        } else {
          let ehTable = data.getTables()[0]; // tables is an array
          let geoTable = data.getTables()[1]; //

          let ehColTemp = [];
          let geoColTemp = [];
          // now get the result rows
          let configObj = {
            type: "range",
            data: {
              from: 0,
              to: geoTable.rows,
              level: 5,
              flat: 0,
              rawValues: 0
            }
          };
          await data.selectRows(1, configObj, async function(code2, geoCol) {
            if (code2) {
              that.setState({
                executing: false,
                alert: {
                  ...this.state.alert,
                  severity: "warning",
                  title: "تنبيه",
                  msg: "مشكلة في جلب تفاصيل التقرير"
                }
              });
              return;
            }
            geoColTemp = geoCol;

            // now get second table details
            configObj = {
              ...configObj,
              data: {
                ...configObj.data,
                to: ehTable.rows
              }
            };
            await data.selectRows(0, configObj, async function(code3, ehCol) {
              if (code3) {
                that.setState({
                  executing: false,
                  alert: {
                    ...this.state.alert,
                    severity: "warning",
                    title: "تنبيه",
                    msg: "مشكلة في جلب تفاصيل التقرير"
                  }
                });
                return;
              }
              ehColTemp = ehCol;

              let datasetTemp = await buildCollection(ehColTemp, geoColTemp);
              await that.setState(
                {
                  dataset: datasetTemp
                },
                () => {
                  that.setState({
                    executing: false
                  });
                  // console.log(that.state);
                }
              );
            });
          });
        }
      }
    );
  }

  onChangeTargetObjects(e, value) {
    if (value) {
      let that = this;
      this.setState({
        selectedTargetObject: value,
        exeReportConfig: {
          ...that.state.exeReportConfig,
          type: value.type
        }
      });
    }
  }

  execute(e) {
    this.setState({
      ...this.state,
      executing: true
    });
    let sessionID = this.state.sessionID;
    this.executeReport1(sessionID);
  }
  onFromDateChange(e) {
    if (e) {
      this.setState({
        selectedFromDate: e
      });
    }
  }
  onToDateChange(e) {
    if (e) {
      this.setState({
        selectedToDate: e
      });
    }
  }
}

export default Main;
