import './App.css';
import Main from './Main'
import {Route,BrowserRouter, Switch} from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createMuiTheme({
  palette: {
    text:{
      primary:'#000000',
      secondary:'#145374'
    },
    primary: {
      main: '#00334e',
    },
    secondary: {
      main: '#ee6f57',
    },
  },
  typography: {
    fontFamily: ["Cairo", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#48678a"
    }
  },
  direction: 'ltr'
});

function App() {
  return (
    <ThemeProvider theme={theme}>
     
        <BrowserRouter basename="/">
    <Switch>
      <Route exact path="/" component={Main} />
    </Switch>
        </BrowserRouter>
    </ThemeProvider>
    
  );
}

export default App;
