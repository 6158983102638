import {
  REPORT_HEADER_COLUMNS,
  REPORT_HEADER_COLUMNS_NAMES
} from "../helpers/Constants";
import { saveAs } from "file-saver";

const ExcelJS = require("exceljs");

export const generateExcel = (dataset, fileName) => {
  let workbook = new ExcelJS.Workbook();
  let sheetName = "التفاصيل";
  let sheet = workbook.addWorksheet(sheetName, {
    pageSetup: { paperSize: 9, orientation: "portrait" }, // 9 is A4 page
    properties: { showGridLines: true },
    views: [
      {
        rightToLeft: true
      }
    ]
  });

  sheet.getRow(1).values = REPORT_HEADER_COLUMNS;
  sheet.columns = REPORT_HEADER_COLUMNS_NAMES;

  sheet.getRow(1).font = { size: 12, bold: true };

  sheet.getRow(1).alignment = {
    vertical: "middle",
    horizontal: "center"
  };

  for (let i = 0; i < dataset.length; i++) {
    let obj = { numbering: dataset[i].c[0], date: dataset[i].c[1] };
    sheet.addRow(obj);
    // sheet.getRow(i + 1).outlineLevel = 1;

    let geofencesArr = dataset[i].r;
    for (let j = 0; j < geofencesArr.length; j++) {
      let obj2 = {
        numbering: geofencesArr[j].c[0],
        geofence: geofencesArr[j].c[1],
        date: dataset[i].c[1]
      };
      sheet.addRow(obj2);

      let units = geofencesArr[j].r;
      for (let k = 0; k < units.length; k++) {
        let obj3 = {
          numbering: units[k].c[0],
          geofence: geofencesArr[j].c[1],
          date: dataset[i].c[1],
          unit_name: units[k].c[1],
          driver_name: units[k].c[3] ? units[k].c[3].driverName : "----",
          engine_hours: units[k].c[3] ? units[k].c[3].engineHours : "----"
        };
        sheet.addRow(obj3);
      }
    }
  }
  let that = this;
  sheet.eachRow(function(row, rowNumber) {
    row.eachCell(function(cell, rowNumberCell) {
      if (rowNumberCell < 7) {
        // console.log(rowNumberCell)
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell.alignment = {
          vertical: "center",
          horizontal: "center",
          wrapText: true
        };
      }
    });
  });
  let str =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], { type: str });
    saveAs(blob, fileName);
  });
};
