import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import DayDate from "./Common/DatePicker";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  autocomplete: {
    margin: "15px"
  },
  button: {
    margin: "15px",
    width: "90%",
    backgroundColor: "#48678a"
  },
  pickers: {
    margin: "15px"
  },
  exportButton: {
    color: "green"
  }
}));

function Controls(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Autocomplete
        className={classes.autocomplete}
        id="targets"
        value={props.values.selectedTargetObject}
        options={props.lists.targetObjects}
        onChange={props.functions.onChangeTargetObjects}
        getOptionLabel={option => (option ? option.caption : "")}
        style={{ width: 250 }}
        renderInput={params => (
          <TextField {...params} label="نوع الهدف" variant="outlined" />
        )}
      />
      {props.values.selectedTargetObject.name == "unit" ? (
        <Autocomplete
          className={classes.autocomplete}
          id="units"
          value={
            props.values.selectedUnit
              ? props.values.selectedUnit
              : props.lists.units
              ? props.lists.units[0]
              : null
          }
          options={props.lists.units ? props.lists.units : []}
          onChange={props.functions.onUnitChange}
          getOptionLabel={option => (option ? option.$$user_name : "")}
          style={{ width: 250 }}
          renderInput={params => (
            <TextField {...params} label="المركبة" variant="outlined" />
          )}
        />
      ) : (
        <Autocomplete
          className={classes.autocomplete}
          id="groups"
          value={
            props.values.selectedGroup
              ? props.values.selectedGroup
              : props.lists.groups
              ? props.lists.groups[0]
              : null
          }
          options={props.lists.groups ? props.lists.groups : []}
          onChange={props.functions.onGroupChange}
          getOptionLabel={option => (option ? option.$$user_name : "")}
          style={{ width: 250 }}
          renderInput={params => (
            <TextField {...params} label="اسم المجموعة" variant="outlined" />
          )}
        />
      )}
      <DayDate
        className={classes.pickers}
        title="من"
        selectedDate={props.values.selectedFromDate}
        handleDateChange={props.functions.onFromDateChange}
      />
      <DayDate
        className={classes.pickers}
        title="الى"
        selectedDate={props.values.selectedToDate}
        handleDateChange={props.functions.onToDateChange}
      />

      <Button
        onClick={props.functions.execute}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        تنفيذ
      </Button>
      {props.values.canExport ? (
        <Button
          className={classes.exportButton}
          onClick={props.functions.export}
          variant="contained"
        >
          تصدير
        </Button>
      ) : null}
    </div>
  );
}

export default Controls;
