export const buildCollection = async (engineHours, NonVisitedGeofences) => {
  let dataset = NonVisitedGeofences;
  for (let i = 0; i < dataset.length; i++) {
    let date = dataset[i].c[1];
    for (let j = 0; j < dataset[i].r.length; j++) {
      //   let units = dataset[i].r[j].r;

      for (let k = 0; k < dataset[i].r[j].r.length; k++) {
        let unitName = dataset[i].r[j].r[k].c[1];
        let dateArrFound = engineHours.find(function(value) {
          return (
            value.c[1] !== "-----" &&
            value.c[3] != "0:00:00" &&
            value.c[1] === date
          );
        });
        if (dateArrFound) {
          let unitFound = dateArrFound.r.find(function(unit) {
            return unit.c[1] == unitName;
          });
          if (unitFound) {
            dataset[i].r[j].r[k].c.push({
              driverName: unitFound.c[2],
              engineHours: unitFound.c[3]
            });
          } else {
            dataset[i].r[j].r[k].c.push({
              driverName: "-----",
              engineHours: "-----"
            });
          }
        }
      }
    }
  }
  return dataset;
};
