import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function DirectionSnackbar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        onClose={props.closeAlert}
        autoHideDuration={6000}
        TransitionComponent={TransitionUp}
        // message={props.msg}
        key={TransitionUp ? TransitionUp.name : ""}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={props.closeAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={props.closeAlert} severity={props.severity}>
          <AlertTitle>{props.title}</AlertTitle>
          {props.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
