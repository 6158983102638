import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider} from "@material-ui/pickers";

import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root:{
    margin:'15px'
  }
}));


export default function MaterialUIPickers(props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
 <MuiPickersUtilsProvider
         utils={DateFnsUtils}>
           <KeyboardDateTimePicker
        id={props.id}
      
        variant="inline"
        ampm={true}
        label={props.title}
        value={props.selectedDate}
        onChange={props.handleDateChange}
        format="yyyy/MM/dd hh:mm"
      />
        </MuiPickersUtilsProvider>
    </div>
       
     
  );
}