export const HOST = {
  TYPE: "local", // local,wialon
  URL: "http://local.wenkgps.com"
};

export const REPORT = {
  AVL: "group", // group, unit, both
  GROUP_NAME: "custom_group",
  UNIT_NAME: "custom_group"
};

export const REQUIREMENTS = {
  UNITS: true,
  GROUPS: true,
  ZONES: false,
  ZONES_GROUPS: false,
  REPORTS: true
};

export const ZONES = {
  ZONE: {
    NAME: ""
  },
  GROUP: {
    NAME: ""
  }
};

export const TABLES_NAMES = {
  ENGINE_HOURS: "engine_hours",
  NON_VISITED_GEOFENCES: "non_visited_geofences"
};

export const REPORT_HEADER_COLUMNS = [
  "ت",
  "التاريخ",
  "المنطقة", // c
  "المركبة", // d
  "السائق", // e
  "ساعات العمل" //e
];
export const REPORT_HEADER_COLUMNS_NAMES = [
  { key: "numbering", width: 7.4 },
  { key: "date", width: 17.57 },
  { key: "geofence", width: 15.29 }, // c
  { key: "unit_name", width: 16.71 }, // d
  { key: "driver_name", width: 13.43 }, // e
  { key: "engine_hours", width: 14.71 } // f
];
