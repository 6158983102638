import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        bottom: '15px',
        width: '100%',
    }
    }
));


const versionNumber = require('../../../package.json').version;

function Version(props) {
    const classes = useStyles()
    
    return (
        <div className={classes.root}>
             <Button disabled onClick={props.openDialog} variant="outlined" color="secondary">
             <span style={{
               color:props.color
           }}> {'v'+versionNumber}</span>
      </Button>
         
        </div>
    )
}

export default Version
